import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import { TagsInput } from "react-tag-input-component";
import axios from "axios";
import Layout from "../Layout";
import "./news.css";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";
import Footer from "../Footer";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cryptoWeb from "../../utilities/cryptoWeb";
import Form from "react-bootstrap/Form";
import Autocomplete from "react-autocomplete";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import InputGroup from "react-bootstrap/InputGroup";
import { faL } from "@fortawesome/free-solid-svg-icons";
function News() {
  const [dataNewsAll, setDataNewsAll] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [dataBodyNews, setDataBodyNews] = useState(null);
  const [dataStealthNews, setDataStealthNews] = useState(null);
  const [dataOtherNews, setDataOtherNews] = useState(null);
  const [latestNews, setLatestNews] = useState([]);
  const [key, setKey] = useState("all-news");
  const [inputSearch, setInputSearch] = useState("");
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState(["tag1", "tag2"]);
  const [isActive, setIsActive] = useState("ข่าวทั้งหมด");

  const [dataListNews, setDataListNews] = useState(null);
  const [showBtn, setShowBtn] = useState(false);
  const [lastNumber, setLastNumber] = useState(0);
  const [totalNumber, setTotalNumber] = useState(0);
  const fetchData = async (category) => {
    setIsActive(category);

    const body = {
      category: cryptoWeb.encryptAES(category),
    };

    try {
      axios
        .post(`https://idmb-services-env.th1.proen.cloud/api/get-news`, body)
        .then((response) => {
          const res = response.data.result;
          const resData = [];
          res.map((data, index) => {
            if (index <= 5) {
              resData.push(data);
            }
          });
          setDataNewsAll(res);
          setDataListNews(resData);
          setLastNumber(res.length);
          setTotalNumber(resData.length);
        });

      try {
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchLatestNews = async () => {
    axios
      .get(`https://idmb-services-env.th1.proen.cloud/api/get-latest-news`)
      .then((response) => {
        const res = response.data.result;

        setLatestNews(res);
      });
    try {
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchTags = async () => {
    console.log(selectedTags);
    try {
      axios
        .post(`https://idmb-services-env.th1.proen.cloud/api/search-tage`, {
          tage: selectedTags,
        })
        .then((response) => {
          const res = response.data.result;
          const bodyNews = [];
          const stealthNews = [];
          const otherNews = [];
          res.map((data) => {
            if (
              cryptoWeb.decryptAES(data.categoryNews) == "ข่าวทำร้ายร่างกาย"
            ) {
              bodyNews.push(data);
            } else if (
              cryptoWeb.decryptAES(data.categoryNews) == "ข่าวชิงทรัพย์"
            ) {
              stealthNews.push(data);
            } else {
              otherNews.push(data);
            }
          });
          setDataBodyNews(bodyNews);
          setDataStealthNews(stealthNews);
          setDataOtherNews(otherNews);
          setDataNewsAll(res);
        });
      try {
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSearch = async () => {
    setIsActive("ข่าวทั้งหมด");
    try {
      axios
        .post(`https://idmb-services-env.th1.proen.cloud/api/search-news`, {
          inputSearch: cryptoWeb.encryptAES(inputSearch),
        })
        .then((response) => {
          const res = response.data.result;
          const resData = [];
          res.map((data, index) => {
            if (index <= 5) {
              resData.push(data);
            }
          });
          setDataNewsAll(res);
          setDataListNews(resData);
          setLastNumber(res.length);
          setTotalNumber(resData.length);
        });
      try {
      } catch (error) {
        console.log("error", error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const selectTage = async (t) => {
    if (!selectedTags.includes(t)) {
      setSelectedTags((tage) => [...tage, t]);
    }

    searchTags();
  };

  useEffect(() => {
    fetchData("ข่าวทั้งหมด");
    fetchLatestNews();
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        axios
          .post(`https://idmb-services-env.th1.proen.cloud/api/search-news`, {
            inputSearch: cryptoWeb.encryptAES(value),
          })
          .then((response) => {
            const res = response.data.result;
            const bodyNews = [];
            const stealthNews = [];
            const otherNews = [];
            res.map((data) => {
              setSuggestions((tage) => [
                ...tage,
                cryptoWeb.decryptAES(data.newsTitle),
              ]);
              if (
                cryptoWeb.decryptAES(data.categoryNews) == "ข่าวทำร้ายร่างกาย"
              ) {
                bodyNews.push(data);
              } else if (
                cryptoWeb.decryptAES(data.categoryNews) == "ข่าวชิงทรัพย์"
              ) {
                stealthNews.push(data);
              } else {
                otherNews.push(data);
              }
            });
            setDataBodyNews(bodyNews);
            setDataStealthNews(stealthNews);
            setDataOtherNews(otherNews);
            setDataNewsAll(res);
          });
        try {
        } catch (error) {
          console.log("error", error);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchSuggestions();
  }, [value]);

  const handleRemoveTag = (tagIndex) => {
    const updatedTags = [...tags];
    updatedTags.splice(tagIndex, 1);
    setTags(updatedTags);
  };

  const readMore = () => {
    const newData = [...dataListNews];
    let count = 0;
    dataNewsAll.map((data, index) => {
      if (!dataListNews.includes(data) && count < 2) {
        newData.push(data);
        count++;
      }
    });
    setDataListNews(newData);
    setTotalNumber(newData.length);
  };
  return (
    <div className="page-container">
      <div className="">
        <div className="container-fluid">
          <div className="row news-title-mobile">
            <div
              className="col-md-8 "
              style={{
                borderRight: "1px solid #ccc",
                paddingRight: "15px",
                position: "relative",
              }}
            >
              <div className="box-h" />
              <h2 className="title"> News</h2>
            </div>

            <div className="col-md-4 ">
              <h2 className="joinMov">ติดตามการเคลื่อนไหวของเรา</h2>
              <button type="button" className="btn joinButton">
                ติดตาม
              </button>
              <hr
                style={{
                  marginTop: "30px",
                  marginLeft: "-13px",
                  marginRight: "-13px",
                }}
              ></hr>
            </div>
          </div>
          <div className="row rowElements">
            <div
              className="col-md-8 news-content-mobile"
              style={{
                borderRight: "1px solid #ccc",

                borderTop: "1px solid #ccc",
              }}
            >
              <div className="form-group has-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text className="custom-input-text">
                    <span className="fa-solid fa-magnifying-glass"></span>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="custom-input"
                    placeholder="ค้นหาข่าว"
                    value={inputSearch}
                    onChange={(e) => setInputSearch(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.keyCode === 13) {
                        handleSearch();
                      }
                    }}
                  />
                </InputGroup>
              </div>

              <div className="news-articles">
                <div className="scrollmenu">
                  <div
                    className={isActive == "ข่าวทั้งหมด" ? "active-menu" : ""}
                    onClick={() => {
                      fetchData("ข่าวทั้งหมด");
                    }}
                  >
                    ข่าวทั้งหมด
                  </div>
                  <div
                    className={
                      isActive == "ข่าวทำร้ายร่างกาย" ? "active-menu" : ""
                    }
                    onClick={() => fetchData("ข่าวทำร้ายร่างกาย")}
                  >
                    ข่าวทำร้ายร่างกาย
                  </div>
                  <div
                    className={isActive == "ข่าวชิงทรัพย์" ? "active-menu" : ""}
                    onClick={() => fetchData("ข่าวชิงทรัพย์")}
                  >
                    ข่าวชิงทรัพย์
                  </div>
                  <div
                    className={isActive == "ข่าวอื่นๆ" ? "active-menu" : ""}
                    onClick={() => fetchData("ข่าวอื่นๆ")}
                  >
                    ข่าวอื่นๆ
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
                {dataListNews &&
                  dataListNews.map((item, key) => (
                    <div key={key} style={{ marginTop: "30px" }}>
                      <div>
                        <div className="row">
                          <div className="col-8 col-sm-9 ">
                            <h6 id="newsDate">
                              {moment(cryptoWeb.decryptAES(item.createdDate))
                                .locale("th")
                                .format("lll")}
                            </h6>
                            <Link
                              to={`/newsArticle/${item._id}`}
                              className="newsLink"
                            >
                              <div className="news-title">
                                {cryptoWeb.decryptAES(item.newsTitle)}
                              </div>
                            </Link>
                            <br></br>
                            <div className="card-content-mobile">
                              {cryptoWeb.decryptAES(item.newsContent)}
                            </div>
                            {item.tage.map((tage, index) => (
                              <button
                                type="button"
                                className="btn tag"
                                key={index}
                                onClick={() => {
                                  selectTage(cryptoWeb.decryptAES(tage));
                                }}
                              >
                                {cryptoWeb.decryptAES(tage)}
                              </button>
                            ))}
                            <span className="category">
                              {cryptoWeb.decryptAES(item.categoryNews)}
                            </span>
                          </div>
                          <div className="col-4 col-sm-3 ">
                            <img
                              src={`data:image/png;base64,${item.pic[0]}`}
                              width="138px"
                              height="138px"
                              className="cardd-news-img"
                              alt="card"
                            />
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                  ))}

                <div
                  style={{
                    marginBottom: "160px",
                    marginTop: "70px",
                    textAlign: "center",
                  }}
                >
                  <button
                    className={`btn read-more ${
                      lastNumber == totalNumber ? "d-none" : ""
                    }`}
                    type="button"
                    onClick={() => readMore()}
                  >
                    อ่านเพิ่มเติม
                  </button>
                </div>
              </div>

              <Footer />
            </div>

            {/* This is the column for latest articles */}

            <div className="col-md-4 latest-news-mobile">
              <div className="latest-news">
                <h2 className="latestNewsTitle">ข่าวล่าสุด</h2>
                {latestNews.slice(0, 3).map((item, index) => (
                  <div className="cardd-latest" key={index}>
                    <div className="row">
                      <div className="col-md-12 col-lg-9">
                        <Link
                          to={`/newsArticle/${item._id}`}
                          className="newsLink"
                        >
                          <div className="latest-news-title">
                            {cryptoWeb.decryptAES(item.newsTitle)}
                          </div>
                        </Link>
                        <h6>
                          {moment(cryptoWeb.decryptAES(item.createdDate))
                            .locale("th")
                            .format("lll")}
                        </h6>
                      </div>
                      <div
                        className="col-md-12 col-lg-3"
                        style={{ marginLeft: "-15px" }}
                      >
                        <Link
                          to={`/newsArticle/${item._id}`}
                          className="newsLink"
                        >
                          <img
                            src={`data:image/png;base64,${item.pic[0]}`}
                            width="120px"
                            height="120px"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr />
              <div className="roww">
                <img src={YOU} className="iconImgNews" alt="..." />
                <img src={FB} className="iconImgNews" alt="..." />
                <img src={TIK} className="iconImgNews" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default News;
