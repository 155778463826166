import React from "react";
import Layout from "../Layout";
import "./index.css";
import { Link } from "react-router-dom";
import img from "../../assets/images/img-q.svg";
import Footer from "../Footer";
import moment from "moment";
import Union from "../../assets/images/Union.png";
import Gif from "../../assets/images/data-security.gif";
import "moment/locale/th";
function Quiz() {
  moment.locale("th");
  return (
    <div className="page-container" style={{ marginTop: "-5px" }}>
      <Layout />
      <div>
        <div className="mobile">
          <div style={{ textAlign: "left", marginTop: "59px" }}>
            <img src={img} />
          </div>
          <hr style={{ marginTop: "0" }}></hr>
        </div>

        <div className="quiz-bg">
          <div className="row box-row">
            <div className="col-6 col-mode-mobile">
              <img
                src={Gif}
                style={{ position: "absolute", right: "0px", top: "-40px" }}
              />
              <img
                src={Union}
                style={{
                  width: "100%",
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                  zIndex: "-1",
                }}
              />
            </div>
            <div className="col-6 width-100">
              <div className="quiz-bg-title-home">
                แบบทดสอบ<br className="quiz-bg-title-mobile"></br>
                วัดภูมิคุ้มกันทางไซเบอร์
              </div>
              <div className="quiz-bg-subheading">
                แบบทดสอบ 5 ข้อเพื่อทดสอบความปลอดภัย <br></br>
                ในการใช้ชีวิตได้อย่างปลอดภัย
              </div>
              <div className="text-left">
                <Link to="/q1">
                  <button type="button" className="btn  quiz-button-home">
                    เริ่มกันเลย!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          overflowY: "auto",
          maxHeight: "600px",
          maxWidht: "100%",
        }}
        className="mobile-news"
      >
        <div className="quiz-bg-title-home">
          แบบทดสอบวัด<br className="quiz-bg-title-mobile"></br>
          ภูมิคุ้มกันทางไซเบอร์
        </div>
        <div className="quiz-bg-subheading">
          แบบทดสอบ 5 ข้อเพื่อทดสอบความปลอดภัย <br></br>
          ในการใช้ชีวิตได้อย่างปลอดภัย
        </div>
        <div className="text-center">
          <Link to="/q1">
            <button
              type="button"
              className="btn  quiz-bg-button-home"
              style={{ borderRadius: "54px" }}
            >
              เริ่มกันเลย!
            </button>
          </Link>
        </div>
        <div style={{ marginTop: "120px" }}>
          <div style={{ textAlign: "center", position: "absolute" }}>
            <img src={Gif} style={{ width: "70%", marginTop: "-130px" }} />
          </div>

          <img
            src={Union}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>

      {/* <div className="quiz-bg-home">
        <div className="row" style={{ height: "600px", paddingTop: "50px" }}>
          <div className="col-6 col-mode-mobile">
            <img
              src={Gif}
              style={{ position: "absolute", right: "0px", top: "-40px" }}
            />
            <img
              src={Union}
              style={{
                width: "100%",
                position: "absolute",
                left: "0",
                bottom: "0",
                zIndex: "-1",
              }}
            />
          </div>
          <div className="col-6 width-100">
            <div className="quiz-bg-title-home">
              แบบทดสอบวัด<br className="quiz-bg-title-mobile"></br>
              ภูมิคุ้มกันทางไซเบอร์
            </div>
            <div className="quiz-bg-subheading">
              แบบทดสอบ 5 ข้อเพื่อทดสอบความปลอดภัย <br></br>
              ในการใช้ชีวิตได้อย่างปลอดภัย
            </div>
            <div className="text-left">
              <Link to="/quiz">
                <button type="button" className="btn  quiz-bg-button-home">
                  ดูแบบทดสอบ
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </div>
  );
}

export default Quiz;
