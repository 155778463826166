import { NavLink } from "react-router-dom";
import "./index.css";
import LOGO from "../../assets/images/logo1.png";
const header = () => {
  return (
    <>
      <div className="row information-navbar">
        <div className="title-h">
          <div className="">เกี่ยวกับเรา</div>
          <div className="">สืบนครบาล IDMB </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <img src={LOGO} className="logo-img contact-logo" alt="" />
        </div>
      </div>
      <div
        className="row contact-subheadings"
        style={{ height: "80px", marginTop: "20px", marginLeft: "10px" }}
      >
        <div>
          <NavLink
            to="/aboutUs"
            className={({ isActive }) => (isActive ? "is-active" : "text-nemu")}
          >
            ผู้บังคับบัญชา
          </NavLink>
          |
          <NavLink
            to="/history"
            className={({ isActive }) => (isActive ? "is-active" : "text-nemu")}
          >
            ประวัติหน่วย
          </NavLink>
          |
          <NavLink
            to="/information"
            className={({ isActive }) => (isActive ? "is-active" : "text-nemu")}
          >
            อำนาจหน้าที่
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default header;
