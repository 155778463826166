import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../Layout";
import "./index.css";
import LOGO from "../../assets/images/logo1.png";
import Footer from "../Footer";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: null,
    };
  }

  toggleCollapse = (id) => {
    if (this.state.activeId === id) {
      this.setState({ activeId: null });
    } else {
      this.setState({ activeId: id });
    }
  };

  render() {
    const data = [
      {
        id: 1,
        title: "ฉันจะติดตามความคืบหน้าคดีได้อย่างไร?",
        content: "Content for Row 1",
      },
      {
        id: 2,
        title: "ฉันจะติดตามความคืบหน้าคดีได้อย่างไร?",
        content: "Content for Row 2",
      },
      {
        id: 3,
        title: "ฉันจะติดตามความคืบหน้าคดีได้อย่างไร?",
        content: "Content for Row 3",
      },
      {
        id: 4,
        title: "ฉันจะติดตามความคืบหน้าคดีได้อย่างไร?",
        content: "Content for Row 4",
      },
      {
        id: 5,
        title: "ฉันจะติดตามความคืบหน้าคดีได้อย่างไร?",
        content: "Content for Row 5",
      },
    ];
    return (
      <div className="page-container">
        <Layout />
        <div className="faq">
          <div
            className="content-faq"
            style={{
              background:
                "radial-gradient(91.54% 86.63% at 4.61% 1.38%, #FFFFFF 0%, #FCFCFC 100%)",
              width: "calc(100%-72px)",
              marginLeft: "72px",
              paddingTop: "150px",
            }}
          >
            <div
              className="row  contact-title-row"
              style={{
                borderBottom: "1px solid #ccc",
                marginLeft: 0,
                paddingLeft: "20px",
              }}
            >
              <div className="col-10 ">
                <h2
                  className=""
                  style={{
                    fontFamily: "Mosse",
                    color: "#060290",
                  }}
                >
                  {" "}
                  ติดต่อเรา{" "}
                </h2>
              </div>
              <div className="col-2 text-end" style={{}}>
                <img
                  src={LOGO}
                  className="contact-logo"
                  style={{
                    height: "60px",
                    width: "100px",
                  }}
                  alt="logo"
                />
              </div>
            </div>
            <div
              className="row contact-subheadings"
              style={{ height: "80px", marginTop: "20px", marginLeft: "5px" }}
            >
              <span>
                <a
                  href="/contactUs"
                  style={{
                    color: "grey",
                    fontWeight: 500,
                    margin: " 20px",
                    textDecoration: "none",
                  }}
                  className="contact-subhead"
                >
                  การให้บริการ
                </a>
                |
                <a
                  href="/contact"
                  style={{
                    color: "grey",
                    fontWeight: 500,
                    margin: " 20px",
                    textDecoration: "none",
                  }}
                  className="contact-subhead"
                >
                  ติดต่อเจ้าหน้าที่
                </a>
                |
                <NavLink
                  style={{
                    fontWeight: 500,
                    margin: " 20px",
                    textDecoration: "none",
                  }}
                  to="/faq"
                  className={({ isActive }) =>
                    isActive ? "active-class" : "contact-subhead"
                  }
                >
                  คำถามที่พบบ่อย
                </NavLink>
              </span>
            </div>

            <div className=" ">
              <div>
                {data.map((item) => (
                  <div key={item.id} style={{ borderBottom: "1px solid #ccc" }}>
                    <div onClick={() => this.toggleCollapse(item.id)}>
                      <h5
                        className="faq-content"
                        style={{ margin: "35px 40px " }}
                      >
                        {" "}
                        <i
                          className={`fa-sharp fa-solid ${
                            this.state.activeId === item.id
                              ? "fa-angle-down"
                              : "fa-angle-right"
                          }`}
                        ></i>{" "}
                        {item.title}
                      </h5>
                    </div>

                    {this.state.activeId === item.id && (
                      <p className="faq-content" style={{ margin: "0px 55px" }}>
                        {" "}
                        {item.content}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="row idmb-icon-res"
            style={{
              height: "500px",
              background:
                "linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%)",
              alignItems: "center",
              justifySelf: "center",
            }}
          >
            <div
              className="roww-new-idmb contact-row-new-idmb-res"
              style={{
                backgroundSize: "100% 100%",
                height: "200px",
                width: "700px",
                margin: "auto",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {/* rowwIcon */}
              <div
                className="row-new-idmb-title"
                style={{ margin: "10px 10px 0px" }}
              >
                <span style={{ color: "white" }}>ติดตามการทำงานได้ที่</span>
                <br></br>

                <img src={YOU} className="iconImg" alt="..." />
                <img src={FB} className="iconImg" alt="..." />
                <img src={TIK} className="iconImg" alt="..." />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default FAQ;
