import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";
import "moment/locale/th";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Layout from "../Layout";
import "./news.css";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";
import cryptoWeb from "../../utilities/cryptoWeb";
import BK from "../../assets/images/n5.png";
import axios from "axios";
import Slider from "react-slick";
function NewsArticle() {
  const { id } = useParams();

  const [articleData, setArticleData] = useState(null);
  const [dataId, setDataId] = useState(id);
  const [latestNews, setLatestNews] = useState(null);
  const [relatedNews, setRelatedNews] = useState(null);
  const [lastNumber, setLastNumber] = useState(0);
  const [totalNumber, setTotalNumber] = useState(0);
  const [dataNewsAll, setDataNewsAll] = useState([]);
  const fetchArticleData = async ({ userId }) => {
    axios
      .get(`http://localhost:3000/api/get-news/${userId}`)
      .then((response) => {
        const res = response.data.result[0];
        setArticleData(res);

        const resData = [];
        response.data.relatedNews.map((data, index) => {
          if (index <= 1) {
            resData.push(data);
          }
        });
        setDataNewsAll(response.data.relatedNews);
        setRelatedNews(resData);
        setLastNumber(response.data.relatedNews.length);
        setTotalNumber(resData.length);
      });
    try {
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchLatestNews = async () => {
    axios.get(`http://localhost:3000/api/get-latest-news`).then((response) => {
      const res = response.data.result;
      setLatestNews(res);
    });
    try {
    } catch (error) {
      console.log("error", error);
    }
  };
  const readMore = () => {
    const newData = [...relatedNews];
    let count = 0;
    dataNewsAll.map((data, index) => {
      if (!relatedNews.includes(data) && count < 2) {
        newData.push(data);
        count++;
      }
    });
    setRelatedNews(newData);
    setTotalNumber(newData.length);
  };
  useEffect(() => {
    fetchArticleData({ userId: dataId });
    fetchLatestNews();
  }, [dataId]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="page-container" style={{ marginTop: "25px" }}>
      <Layout />
      <div className="page-body">
        <div className="mainView-container">
          <div className="mainView-header">
            <div className="mainView-header-title">
              <h3>ข่าวสาร</h3>
            </div>
            <div className="mainView-header-subTitle">
              <h1>
                {articleData && cryptoWeb.decryptAES(articleData.newsTitle)}
              </h1>
            </div>
            <div className="mainView-header-date">
              <h6>
                {moment(
                  articleData && cryptoWeb.decryptAES(articleData.createdDate)
                ).format("lll")}
              </h6>
            </div>
          </div>
          <div className="mainView-body">
            <img
              src={`data:image/png;base64,${articleData && articleData.pic[0]}`}
              className="newsArticleImg"
              alt="news"
            />
            <p className="newsArticlePara">
              {articleData && cryptoWeb.decryptAES(articleData.newsContent)}
            </p>
            <p className="newsArticlePara">
              {/* {articleData && cryptoWeb.decryptAES(articleData.content_para2)} */}
            </p>
            {/* <img
              src={articleData && articleData.images[1]}
              className="newsArticleImg"
              alt="news"
            /> */}
            <p className="newsArticlePara">
              {/* {articleData && articleData.content_para3} */}
            </p>
            <p className="newsArticlePara">
              {/* {articleData && articleData.content_para4} */}
            </p>
          </div>
          <div className="latest-container">
            {relatedNews &&
              relatedNews.map((item, key) => (
                <>
                  <div className="row" key={key}>
                    <div className="col-8 col-sm-9 ">
                      <Link
                        to={`/newsArticle/${item._id}`}
                        className="newsLink"
                        onClick={() => fetchArticleData({ userId: item._id })}
                      >
                        <h2
                          style={{
                            color: "#000",
                            fontFamily: "Mosse",
                            fontSize: "28px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "126%",
                            letterSpacing: "-0.42px",
                          }}
                        >
                          {cryptoWeb.decryptAES(item.newsTitle)}
                        </h2>
                      </Link>
                      <div className="text-content">
                        {cryptoWeb.decryptAES(item.newsContent)}
                      </div>
                    </div>
                    <div className="col-4 col-sm-3 text-end">
                      <img
                        src={`data:image/png;base64,${item.pic[0]}`}
                        width="120px"
                        height="120px"
                        alt=""
                        style={{ marginTop: "30px" }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      {item.tage.map((tage, index) => (
                        <button
                          type="button"
                          className="btn btn-title"
                          key={index}
                        >
                          {cryptoWeb.decryptAES(tage)}
                        </button>
                      ))}
                      <div className="date-news">
                        {" "}
                        {moment(cryptoWeb.decryptAES(item.createdDate))
                          .locale("th")
                          .format("lll")}{" "}
                        น.
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))}

            <div className="box-read-more">
              <button
                type="button"
                className={`btn read-more ${
                  lastNumber == totalNumber ? "d-none" : ""
                }`}
                onClick={() => readMore()}
              >
                อ่านเพิ่มเติม
              </button>
            </div>
          </div>
          <div style={{ borderRight: "1px solid #00000024" }}>
            <Footer />
          </div>
        </div>
        <div className="sideView-container">
          <div className="sideView-header">
            <div className="sideView-header-title">
              <h3>ติดตามการเคลื่อนไหวของเรา</h3>
            </div>
            <button type="button" className="sideView-header-button">
              ติดตาม
            </button>
          </div>
          <hr
            style={{
              marginTop: "0px",
              marginBottom: "30px",
            }}
          ></hr>
          <div className="latest-news">
            <h2 className="latestNewsTitle">ข่าวล่าสุด</h2>
            {latestNews &&
              latestNews.slice(0, 3).map((item, index) => (
                <div className="cardd-latest">
                  <div className="row" key={index}>
                    <div className="col-md-12 col-lg-8">
                      <Link
                        to={`/newsArticle/${item._id}`}
                        className="newsLink"
                        onClick={() => fetchArticleData({ userId: item._id })}
                      >
                        <div className="latest-news-title">
                          {cryptoWeb.decryptAES(item.newsTitle)}
                        </div>
                      </Link>
                      <h6>
                        {moment(cryptoWeb.decryptAES(item.createdDate))
                          .locale("th")
                          .format("lll")}
                      </h6>
                    </div>
                    <div
                      className="col-md-12 col-lg-4"
                      style={{ marginLeft: "-15px" }}
                    >
                      <Link
                        to={`/newsArticle/${item._id}`}
                        className="newsLink"
                      >
                        <img
                          src={`data:image/png;base64,${item.pic[0]}`}
                          width="120px"
                          height="120px"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="sideView-footer">
            <img src={YOU} className="iconImgNews" alt="..." />
            <img src={FB} className="iconImgNews" alt="..." />
            <img src={TIK} className="iconImgNews" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsArticle;
