import React, { Component } from "react";

import Layout from "../Layout";
// import "./index.css";
import LOGO from "../../assets/images/l2.png";
import Footer from "../Footer";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";
import Header from "./header";
export class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: null,
    };
  }

  toggleCollapse = (id) => {
    if (this.state.activeId === id) {
      this.setState({ activeId: null });
    } else {
      this.setState({ activeId: id });
    }
  };
  render() {
    return (
      <div className="page-container">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="row about-commander-box">
            <div className="about-commander-box-right">
              <div className="body-middle-container">
                <div className="box-container">
                  <Header />
                  <div className=" row-faq">
                    <h3 className="info-heading">อำนาจหน้าที่</h3>
                    <h4 className="info-subheading">
                      กองบังคับการสืบสวนสอบสวนมีอํานาจหน้าที่ ดังต่อไปนี้
                    </h4>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      1) ถวายความปลอดภัยสําหรับองค์พระมหากษัตริย์, พระราชินี,
                      พระรัชทายาท, ผู้สําเร็จราชการแทนพระองค์, พระบรมวงศานุวงศ,
                      ผู้แทนพระองค์ และพระราชอาคันตุกะ รวมทั้งรักษาความ
                      ปลอดภัยบุคคลสําคัญอื่นๆ
                    </h5>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      {" "}
                      2) ปฏิบัติงานตามประมวลกฎหมายวิธีพิจารณาความอาญา
                      และกฎหมายอื่นอันเกี่ยวกับความผิดทางอาญา
                    </h5>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      {" "}
                      3) ดําเนินการเกี่ยวกับงานสืบสวนสอบสวนอาชญากรรม
                      ที่มีลักษณะเป็นองค์กรหรือผู้มีอิทธิพลที่คาบเกี่ยวระหว่างหลายพื้นที่
                    </h5>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      {" "}
                      4) ดําเนินการด้านกรรมวิธีข่าวกรอง
                      และเป็นศูนย์รวบรวมข้อมูลวิเคราะห์และติดตาม
                      สถานการณ์อาชญากรรมและความมั่นคงในเขตอํานาจการรับผิดชอบ
                    </h5>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      {" "}
                      5) สืบสวนสอบสวนคดีอาญา
                      โดยการใช้เทคโนโลยีสารสนเทศและเครื่องมือพิเศษ
                      รวมทั้งพัฒนาบุคลากรด้านการสืบสวนสอบสวน
                      ให้มีความรู้ในด้านการใช้เทคโนโลยีและเครื่องมือพิเศษ
                    </h5>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      {" "}
                      6) ให้คําปรึกษา
                      แนะนําหน่วยงานสืบสวนในสังกัดกองบัญชาการตํารวจนครบาล
                    </h5>
                    <hr></hr>
                    <h5 className="faq-questions-info">
                      {" "}
                      7) สืบสวนติดตามคดีสําคัญตามที่ได้รับมอบหมาย
                    </h5>
                    <hr></hr>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rowIcon">
          <div className="rowwIcon">
            <div className="rowwwIcon">
              <span>ติดตามการทำงานได้ที่</span>
              <br></br>
              <img src={YOU} className="iconImg" alt="..." />
              <img src={FB} className="iconImg" alt="..." />
              <img src={TIK} className="iconImg" alt="..." />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Information;
