import Layout from "../Layout";
import "./index.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import Footer from "../Footer";
import FB from "../../assets/images/fb.png";
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

function Final() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="page-container">
      <Layout />
      <hr />
      <div className="row bg">
        <div className="row-quiz">
          <span className="quiz-correct"> 5</span>
          <span className="quiz-correct-sub"> จากทั้งหมด 5 </span>
          <div className="quiz-correct-title">
            {" "}
            ยินดีด้วย!
            <br /> หวังว่ามันจะช่วยคุณนะ!
          </div>
        </div>
        <div className="quizz-final">
          <div className="subheading">
            การรู้เท่าทันกลโกงของมิจฉาชีพ<br></br>{" "}
            จะช่วยให้คุณปลอดภัยในโลกออนไลน์
          </div>
          <Link to="/q1">
            <button
              type="button"
              className="btn check-font fon-size-17"
              style={{
                color: "#FFFFFF",
                background: "#FF8A00",
                border: "4px solid #FF8A00",
                borderRadius: "8px",
                marginLeft: "10px",
                fontSize: "22px",
                marginRight: "24px",
              }}
            >
              ทำใหม่อีกครั้ง
            </button>
          </Link>{" "}
          <button
            type="button"
            className="btn check-font fon-size-17"
            onClick={handleShow}
            style={{
              color: "#FF8A00",
              background: "#FFFFFF",
              border: "4px solid #FF8A00",
              borderRadius: "8px",
              marginRight: "10px",
              fontSize: "22px",
            }}
          >
            แชร์ผลทดสอบ
          </button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="title-share">แชร์ผลทดสอบ</div>
          <div className="m-body">
            <img src={FB} width={39} height={40} />
            <div style={{ padding: "10px 15px" }}>Facebook</div>
          </div>
          <div style={{ padding: "0px 50px 20px" }}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="https://translate.google.com/?sl=en&tl"
                  value={"https://translate.google.com/?sl=en&tl"}
                />
              </Form.Group>
            </Form>
            <button className="btn btn-next">คัดลอกลิงก์</button>
          </div>
        </Modal.Body>
      </Modal>
      <hr />
      <div className="footer-quiz-res-mb">
        <Footer />
      </div>
    </div>
  );
}

export default Final;
