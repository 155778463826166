import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import hamburger from "../../assets/images/hamburger.png";
import close from "../../assets/images/close.png";
import logo from "../../assets/images/logo.svg";
import logoMobile from "../../assets/images/logo-mobile.svg";

import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";

import "./index.css";

const Layout = () => {
  const [clicked, setClicked] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    setClicked((prevClicked) => !prevClicked);
  };
  const navbarClasses = clicked ? "active" : "";
  const [lang, setLang] = useState("TH");

  const toggleLanguage = () => {
    const newLang = lang === "TH" ? "EN" : "TH";
    setLang(newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <Navbar expand="md" className="layout-navbar " fixed="top">
      <Navbar.Brand as={Link} to="/home" style={{ marginLeft: "18px" }}>
        <img src={logo} className="show-logo" />
        <img src={logoMobile} className="show-logo-mobile" />
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="layout-navbar-collapse"
        onClick={handleClick}
      >
        <img
          src={clicked ? close : hamburger}
          alt={clicked ? "close icon" : "hamburger icon"}
          width={70}
          height={66}
          className={clicked ? "" : "collapsed"}
        />
      </Navbar.Toggle>

      <Navbar.Collapse>
        <Nav className="nav-items-mobile align-items-center">
          {/* <Nav.Link
            as={Link}
            to="/home"
            className={`nav-link nav-link-news nav-link-mobile ${navbarClasses}`}
            onClick={handleClick}
          >
            {t("home")}
          </Nav.Link> */}

          <NavLink
            as={Link}
            to="/news"
            className={({ isActive }) =>
              isActive
                ? "active nav-link nav-link-news"
                : "nav-link nav-link-news"
            }
            onClick={handleClick}
          >
            {t("news")}
            {/* ข่าวสาร */}
          </NavLink>
          <NavDropdown
            title={t("about_us")}
            className="nav-link nav-link-information"
          >
            <NavDropdown.Item href="/aboutUs">ผู้บังคับบัญชา</NavDropdown.Item>
            <NavDropdown.Item href="/history">ประวัติหน่วย</NavDropdown.Item>
            <NavDropdown.Item href="/information">
              อำนาจหน้าที่
            </NavDropdown.Item>
          </NavDropdown>

          <NavLink
            as={Link}
            to="/faq"
            className={({ isActive }) =>
              isActive
                ? "active nav-link nav-link-faq"
                : "nav-link nav-link-faq"
            }
            onClick={handleClick}
          >
            {t("faq")}
          </NavLink>

          <NavLink
            as={Link}
            to="/contactUs"
            className={({ isActive }) =>
              isActive
                ? "active nav-link nav-link-contact"
                : "nav-link nav-link-contact"
            }
            onClick={handleClick}
          >
            {t("contact_us")}
          </NavLink>
        </Nav>

        {/* <Nav className="ml-auto language-switcher nav-items-mobile">
          <NavLink
            as={Link}
            to=""
            className="nav-link-language-switcher"
            onClick={toggleLanguage}
          >
            <span className="layout-navv-icon-lang">{lang}</span>
          </NavLink>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Layout;
