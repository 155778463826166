import React from "react";
import { Link } from "react-router-dom";
import MAP from "../../assets/images/map.png";
import "./index.css";
import logo from "../../assets/images/logo.svg";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-title">
          <Link to="/home">
            <img src={logo} />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={MAP} className="iconFooter " alt="map" />
          <div className="footer-mapLink">
            <Link href="#" rel="noopener noreferrer">
              71/1 กองบังคับการสืบสวนสอบสวน บช.น. ถ.ศรีอยุธยา แขวงทุ่งพญาไท
              เขตราชเทวี กรุงเทพมหานคร 10400
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <span>@2023. All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Footer;
