import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../Layout";
import "./index.css";
import LOGO from "../../assets/images/logo1.png";
import C from "../../assets/images/c1.png";
import Ph from "../../assets/images/ph.png";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";
import Footer from "../Footer";

function Contact() {
  return (
    <div className="page-container">
      <Layout />
      <div className="contactUs">
        <div
          className="content"
          style={{
            background:
              "radial-gradient(91.54% 86.63% at 4.61% 1.38%, #FFFFFF 0%, #FCFCFC 100%)",
            width: "calc(100%-72px)",
            marginLeft: "72px",
            paddingTop: "150px",
          }}
        >
          <div
            className="row contact-title-row"
            style={{
              borderBottom: "1px solid #ccc",
              marginLeft: 0,
              paddingLeft: "20px",
            }}
          >
            <div className="col-10 ">
              <h2
                className=""
                style={{
                  fontFamily: "Mosse",
                  color: "#060290",
                  fontSize: "40px",
                  fontWeight: "700",
                }}
              >
                ติดต่อเรา{" "}
              </h2>
            </div>
            <div className="col-2 text-end">
              <img
                src={LOGO}
                className="contact-logo"
                style={{
                  height: "60px",
                  width: "100px",
                }}
                alt="logo"
              />
            </div>
          </div>
          <div
            className="row contact-subheadings"
            style={{ height: "80px", marginTop: "20px", marginLeft: "5px" }}
          >
            <span>
              <a
                href="/contactUs"
                style={{
                  color: "grey",
                  fontWeight: 500,
                  margin: " 20px",
                  textDecoration: "none",
                }}
                className="contact-subhead"
              >
                การให้บริการ{" "}
              </a>{" "}
              |
              <NavLink
                style={{
                  fontWeight: 500,
                  margin: " 20px",
                  textDecoration: "none",
                }}
                to="/contact"
                className={({ isActive }) =>
                  isActive ? "active-class" : "contact-subhead"
                }
              >
                ติดต่อเจ้าหน้าที่
              </NavLink>
              |
              <a
                href="/faq"
                style={{
                  color: "grey",
                  fontWeight: 500,
                  margin: " 20px",
                  textDecoration: "none",
                }}
                className="contact-subhead"
              >
                {" "}
                คำถามที่พบบ่อย
              </a>
            </span>
          </div>

          <div
            className="row"
            style={{
              boxShadow: "-17px 30px 64px rgba(0, 0, 0, 0.12)",
              height: "440px",
            }}
          >
            <div className="col-md-5 contact-map-col">
              <div className="" style={{ position: "relative", top: "25%" }}>
                <div
                  className="fontt contact-map-title"
                  style={{
                    color: "#0d1d2f",
                    fontSize: "25px",
                    lineHeight: "116.6%",
                    textAlign: "left",
                    marginBottom: "20px",
                    marginLeft: "40px",
                    fontWeight: "500",
                    letterSpacing: "0.005em",
                  }}
                >
                  {" "}
                  ติดต่อเจ้าหน้าที่ <img src={Ph} className="iconMap" alt="" />
                </div>

                <div
                  className="fontt contact-map-subtitle"
                  style={{
                    color: "grey",
                    fontSize: "15px",
                    textAlign: "left",
                    marginLeft: "40px",
                  }}
                >
                  คลิก”คุยกับเจ้าหน้าที่” เพื่อติดต่อเจ้าที่ผ่าน messenger
                </div>

                <div className="contact-map-btn-pos">
                  <button
                    type="button"
                    className="btn contact-map-btn"
                    style={{
                      background: "#060290",
                      borderRadius: "40px",
                      marginTop: "30px",
                      marginLeft: "40px",
                      color: "#ffffff",
                      fontFamily: "Mosse",
                      fontSize: "15px",
                      padding: "15px 20px",
                    }}
                  >
                    คุยกับเจ้าหน้าที่
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-7 contact-map-col2">
              <img src={C} className="contact-map-img" alt="contact-map" />
            </div>
          </div>
        </div>

        <div
          className="row idmb-icon-res"
          style={{
            height: "500px",
            background:
              "linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%)",
            alignItems: "center",
            justifySelf: "center",
          }}
        >
          <div
            className="roww-new-idmb contact-row-new-idmb-res"
            style={{
              backgroundSize: "100% 100%",
              height: "200px",
              width: "700px",
              margin: "auto",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {/* rowwIcon */}
            <div
              className="row-new-idmb-title"
              style={{ margin: "10px 10px 0px" }}
            >
              <span style={{ color: "white" }}>ติดตามการทำงานได้ที่</span>
              <br></br>

              <img src={YOU} className="iconImg" alt="..." />
              <img src={FB} className="iconImg" alt="..." />
              <img src={TIK} className="iconImg" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
