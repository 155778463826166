import { Link } from "react-router-dom";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/th";

import "./index.css";
import BG from "../../assets/images/Frame.svg";
import BG1 from "../../assets/images/bg-color.svg";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";
import MAP from "../../assets/images/map.png";

import "../Home/slickk-theme.css";
import "../Home/slickk.css";
import Layout from "../Layout";
import Footer from "../Footer";

import IMG from "../../assets/images/review.png";
import IMG1 from "../../assets/images/review1.svg";
import Facebook from "../../assets/images/facebook.svg";
import Youtube from "../../assets/images/youtube.png";
import Tiktok from "../../assets/images/tiktok.png";
import Union from "../../assets/images/Union.png";
import Gif from "../../assets/images/data-security.gif";
import axios from "axios";
import { Helmet } from "react-helmet";
import cryptoWeb from "../../utilities/cryptoWeb";
function Home() {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    axios
      .get(`https://idmb-services-env.th1.proen.cloud/api/get-latest-news`)
      .then((response) => {
        const res = response.data.result;
        const resData = [];
        res.map((data, index) => {
          if (index <= 3) {
            resData.push(data);
          }
        });
        setData(resData);
      });
    try {
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    swipeToSlide: true,

    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <main className="page-container">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>IDMB Home</title>
         <meta name="description" content="Helmet application" />
      </Helmet> */}
      <section className="home">
        <div>
          <div className="row rowCarousel ">
            <div className="col-6 colSlogan">
              <div>
                <Carousel>
                  <Carousel.Item className="colCarousel">
                    <Carousel.Caption>
                      <div className="text-carousel">
                        <span></span>
                        Even if the damage is minor, if it causes people to
                        suffer, we prioritize immediate action.
                      </div>
                      <div className="text-carousel1">
                        Pol.Maj.Gen. Teeradej Thumsutee Investigation Division,
                        Metropolitan Police Bureau
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item className="colCarousel">
                    <Carousel.Caption>
                      <div className="text-carousel">
                        <span></span>
                        News Analysis Supervision Division and Special Tools
                      </div>
                      <div className="text-carousel1">TEAM 4</div>
                      <br />
                      <div className="text-left">
                        <img src={IMG} />
                        <img src={IMG1} />
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item className="colCarousel">
                    <Carousel.Caption>
                      <div className="text-carousel">
                        Adore the organization Honorable, dignified.
                      </div>
                      <div className="text-carousel1">
                        Investigation Division Metropolitan Police Bureau
                      </div>
                      <br />
                      <div className="text-left">
                        <img src={Facebook} />
                        &nbsp; &nbsp;
                        <img src={Youtube} />
                        &nbsp; &nbsp;
                        <img src={Tiktok} />
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
              <img src={BG1} className="styleBg" alt="..." />
              <img src={BG} className="styleImg" alt="..." />
            </div>
            <div className="col-6 "></div>
          </div>

          <div className="row styleScroll">
            {" "}
            <marquee className="text-marquee">
              {data.map((newsItem, index) => (
                <span key={index}>
                  {" "}
                  {cryptoWeb.decryptAES(newsItem.newsTitle)} |
                </span>
              ))}
            </marquee>
          </div>

          <div style={{ display: "flex" }}>
            <div className="styleLeft"></div>
            <div className="styleRightCardCarousal">
              <h3 className="titleCard">ข่าวล่าสุด</h3>
              <div className={`box-news`}>
                {data.map((newsItem, index) => (
                  <div key={index}>
                    <Link
                      to={`/newsArticle/${newsItem._id}`}
                      style={{ textDecoration: "auto" }}
                    >
                      <div className="card border-0 card-news">
                        <img
                          src={`data:image/png;base64,${newsItem.pic[0]}`}
                          className="card-img-top"
                          alt="..."
                        />

                        <div
                          style={{
                            position: "absolute",
                            bottom: "165px",
                            display: "flex",
                          }}
                        >
                          {newsItem.tage &&
                            newsItem.tage.map((tage, key) => (
                              <div className="tage" key={key}>
                                {cryptoWeb.decryptAES(tage)}
                              </div>
                            ))}
                        </div>

                        <div
                          className="card-body"
                          style={{ background: "#FFF", zIndex: "1" }}
                        >
                          <div className="box-content">
                            <h5 className="card-title">
                              {cryptoWeb.decryptAES(newsItem.newsTitle)}
                            </h5>
                            <p className="card-text">
                              {cryptoWeb.decryptAES(newsItem.newsContent)}
                            </p>
                            <h6 className="card-date">
                              {moment(
                                cryptoWeb.decryptAES(newsItem.createdDate)
                              )
                                .locale("th")
                                .format("lll")}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div
                className="text-end box-see-more"
                style={{
                  padding: "20px 50px",
                }}
              >
                <Link
                  to={`/news`}
                  className="see-more"
                  style={{ textDecoration: "auto" }}
                >
                  ดูทั้งหมด {`>>`}
                </Link>
              </div>

              <div className="quiz-tablet">
                <div className="quiz-bg-home">
                  <div
                    className="row"
                    style={{ height: "600px", paddingTop: "50px" }}
                  >
                    <div className="col-6 col-mode-mobile">
                      <img
                        src={Gif}
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "-40px",
                        }}
                      />
                      <img
                        src={Union}
                        style={{
                          width: "100%",
                          // position: "absolute",
                          // left: "0",
                          // bottom: "0",
                          // zIndex: "-1",
                          marginTop: "133px",
                        }}
                      />
                    </div>
                    <div
                      className="col-6 width-100"
                      style={{ paddingLeft: "100px" }}
                    >
                      <div className="quiz-bg-title-home">
                        แบบทดสอบวัด<br className="quiz-bg-title-mobile"></br>
                        ภูมิคุ้มกันทางไซเบอร์
                      </div>
                      <div className="quiz-bg-subheading">
                        แบบทดสอบ 5 ข้อเพื่อทดสอบความปลอดภัย <br></br>
                        ในการใช้ชีวิตได้อย่างปลอดภัย
                      </div>
                      <div className="text-left">
                        <Link to="/quiz">
                          <button
                            type="button"
                            className="btn  quiz-bg-button-home"
                          >
                            ดูแบบทดสอบ
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="quiz-mobile">
                <div className="quiz-bg-title-home">
                  แบบทดสอบวัด<br></br>
                  ภูมิคุ้มกันทางไซเบอร์
                </div>
                <div className="quiz-bg-subheading">
                  แบบทดสอบ 5 ข้อเพื่อทดสอบความปลอดภัย <br></br>
                  ในการใช้ชีวิตได้อย่างปลอดภัย
                </div>
                <div className="text-center">
                  <Link to="/quiz">
                    <button type="button" className="btn  quiz-bg-button-home">
                      ดูแบบทดสอบ
                    </button>
                  </Link>
                </div>
                <div style={{ marginTop: "120px", marginBottom: "120px" }}>
                  <div style={{ textAlign: "center", position: "absolute" }}>
                    <img
                      src={Gif}
                      style={{ width: "70%", marginTop: "-130px" }}
                    />
                  </div>
                  <img src={Union} style={{ width: "100%" }} />
                </div>
              </div>
              <div className="w-100 text-center ">
                <div className="row">
                  <div className="col-md-6 contact-map-col align-self-center">
                    <div
                      className="fontt contact-map-title"
                      style={{
                        color: "#0d1d2f",
                        fontSize: "25px",
                        lineHeight: "116.6%",

                        marginBottom: "20px",
                        marginLeft: "40px",
                        fontWeight: "500",
                        letterSpacing: "0.005em",
                      }}
                    >
                      จุดให้บริการเพื่อประชาชน{" "}
                      <img src={MAP} className="iconMap" alt="map" />
                    </div>

                    <div
                      className="fontt contact-map-subtitle"
                      style={{
                        color: "grey",
                        fontSize: "15px",

                        marginLeft: "40px",
                      }}
                    >
                      71/1 กองบังคับการสืบสวนสอบสวน บช.น. ถ.ศรีอยุธยา
                      แขวงทุ่งพญาไท <br></br>
                      เขตราชเทวี กรุงเทพมหานคร 10400
                    </div>
                    <div className="contact-map-btn-pos">
                      <button
                        type="button "
                        className="btn contact-map-btn"
                        style={{
                          background: "#060290",
                          borderRadius: "40px",
                          marginTop: "30px",
                          marginLeft: "40px",
                          color: "#ffffff",
                          fontFamily: "Mosse",
                          fontSize: "15px",
                          padding: "15px 20px",
                        }}
                      >
                        ขอทราบเส้นทาง
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 contact-map-col-12">
                    <iframe
                      title="map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.5860813312356!2d78.39170750000001!3d17.479514499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9185969ec633%3A0x50c71941b7b668de!2sMalaysian%20Twp%2C%20KPHB%205th%20Phase%2C%20Kukatpally%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1680424988372!5m2!1sen!2sin"
                      width="100%"
                      height="440"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row idmb-icon-res"
            style={{
              height: "500px",

              background:
                "var(--bg-blue, linear-gradient(222deg, #151015 0%, #060290 100%))",
              alignItems: "center",
              justifySelf: "center",
            }}
          >
            <div
              className="roww-new-idmb contact-row-new-idmb-res"
              style={{
                backgroundSize: "100% 100%",
                height: "200px",
                width: "700px",
                margin: "auto",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {/* rowwIcon */}
              <div
                className="row-new-idmb-title"
                style={{ margin: "10px 10px 0px" }}
              >
                <span style={{ color: "white" }}>ติดตามการทำงานได้ที่</span>
                <br></br>
                <img src={YOU} className="iconImg" alt="..." />
                <img src={FB} className="iconImg" alt="..." />
                <img src={TIK} className="iconImg" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <Footer />
      </footer>
    </main>
  );
}

export default Home;
