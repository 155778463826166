import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locale/translations/en.json';
import translationTH from './assets/locale/translations/th.json';

const resources = {
  EN: {
    translation: translationEN,
  },
  TH: {
    translation: translationTH,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'TH',
  fallbackLng: 'EN',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
