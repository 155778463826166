import "./App.css";
import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
// import AboutUs from "./components/AboutUs";
import News from "./components/News";
import Quiz from "./components/Quiz";
import FAQ from "./components/Faq";
import Contact from "./components/ContactUs/contact";
import Q1 from "./components/Quiz/q1";
import Q2 from "./components/Quiz/q2";
import Q3 from "./components/Quiz/q3";
import Q4 from "./components/Quiz/q4";
import Q5 from "./components/Quiz/q5";
import Final from "./components/Quiz/final";
import Information from "./components/AboutUs/information";
//import History from "./components/AboutUs/Histoey/history";
import Footer from "./components/Footer";
import NewsArticle from "./components/News/newsArticle";
import Loader from "./components/Loader";
const History = React.lazy(() =>
  import("./components/AboutUs/Histoey/history")
);

const AboutUs = React.lazy(() => import("./components/AboutUs"));

function App() {
  return (
    <BrowserRouter>
      <Layout />
      <Routes>
        <Route path="/" element={<Loader />} />
        <Route path="/layout" element={<Layout />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/aboutUs"
          element={
            <React.Suspense fallback={<>...</>}>
              <AboutUs />
            </React.Suspense>
          }
        />
        <Route
          path="/news"
          element={
            <React.Suspense fallback={<>...</>}>
              <News />
            </React.Suspense>
          }
        />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/q1" element={<Q1 />} />
        <Route path="/q2" element={<Q2 />} />
        <Route path="/q3" element={<Q3 />} />
        <Route path="/q4" element={<Q4 />} />
        <Route path="/q5" element={<Q5 />} />
        <Route path="/final" element={<Final />} />

        <Route path="/information" element={<Information />} />
        <Route
          path="/history"
          element={
            <React.Suspense fallback={<>...</>}>
              <History />
            </React.Suspense>
          }
        />

        <Route path="/footer" element={<Footer />} />
        <Route path="/newsArticle/:id" element={<NewsArticle />} />
        <Route path="/loader" element={<Loader />} />
      </Routes>
    </BrowserRouter>
    //<div className="App">
    //<Layout/>
    // <Home/>
    // </div>
  );
}

export default App;
