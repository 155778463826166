import React from "react";
import "./index.css";
import LO2 from "../../assets/images/lo2.png";
import LO1 from "../../assets/images/lo1.png";
import { useNavigate } from "react-router-dom";

function Loader() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/home");
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate]);

  return (
    <div className="loader">
      <img src={LO2} className="left-image" alt="Left" />
      <img src={LO1} className="right-image" alt="Right" />
    </div>
  );
}
export default Loader;
