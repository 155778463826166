import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../Layout";
import "./index.css";
import LOGO from "../../assets/images/logo1.png";
import Footer from "../Footer";
import MAP from "../../assets/images/map.png";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";

function ContactUs() {
  return (
    <div className="page-container">
      <Layout />
      <div className="contactUs">
        <div
          className="content"
          style={{
            background:
              "radial-gradient(91.54% 86.63% at 4.61% 1.38%, #FFFFFF 0%, #FCFCFC 100%)",
            width: "calc(100%-72px)",
            marginLeft: "72px",
            paddingTop: "150px",
          }}
        >
          <div
            className="row contact-title-row"
            style={{
              borderBottom: "1px solid #ccc",
              marginLeft: 0,
              paddingLeft: "20px",
            }}
          >
            <div className="col-10 ">
              <h2
                className=""
                style={{
                  fontFamily: "Mosse",
                  color: "#060290",
                  fontSize: "40px",
                  fontWeight: "700",
                }}
              >
                {/* information-title info-top-title */}
                ติดต่อเรา
              </h2>
            </div>
            <div className="col-2 text-end">
              <img
                src={LOGO}
                className="contact-logo"
                style={{
                  height: "60px",
                  width: "100px",
                }}
                alt="logo"
              />
              {/* row-title-img */}
            </div>
          </div>

          <div
            className="row contact-subheadings"
            style={{ height: "80px", marginTop: "20px", marginLeft: "5px" }}
          >
            <span>
              <NavLink
                style={{
                  fontWeight: 500,
                  margin: " 20px",
                  textDecoration: "none",
                }}
                to="/contactUs"
                className={({ isActive }) =>
                  isActive ? "active-class" : "contact-subhead"
                }
              >
                การให้บริการ
              </NavLink>
              |
              <a href="/contact" className="contact-subhead">
                {" "}
                ติดต่อเจ้าหน้าที่
              </a>{" "}
              |
              <a href="/faq" className="contact-subhead">
                {" "}
                คำถามที่พบบ่อย
              </a>
            </span>
          </div>

          <div
            className="row "
            style={{
              boxShadow: "-17px 30px 64px rgba(0, 0, 0, 0.12)",
              height: "440px",
            }}
          >
            {/* contact-map */}
            <div className="col-md-6 contact-map-col">
              <div className="" style={{ position: "relative", top: "25%" }}>
                {/* map */}
                <div
                  className="fontt contact-map-title"
                  style={{
                    color: "#0d1d2f",
                    fontSize: "25px",
                    lineHeight: "116.6%",
                    textAlign: "left",
                    marginBottom: "20px",
                    marginLeft: "40px",
                    fontWeight: "500",
                    letterSpacing: "0.005em",
                  }}
                >
                  จุดให้บริการเพื่อประชาชน{" "}
                  <img src={MAP} className="iconMap" alt="iconMap" />
                </div>

                <div
                  className="fontt contact-map-subtitle"
                  style={{
                    color: "grey",
                    fontSize: "15px",
                    textAlign: "left",
                    marginLeft: "40px",
                  }}
                >
                  71/1 กองบังคับการสืบสวนสอบสวน บช.น. ถ.ศรีอยุธยา แขวงทุ่งพญาไท{" "}
                  <br></br>
                  เขตราชเทวี กรุงเทพมหานคร 10400
                </div>
                <div className="contact-map-btn-pos">
                  <button
                    type="button "
                    className="btn contact-map-btn"
                    style={{
                      background: "#060290",
                      borderRadius: "40px",
                      marginTop: "30px",
                      marginLeft: "40px",
                      color: "#ffffff",
                      fontFamily: "Mosse",
                      fontSize: "15px",
                      padding: "15px 20px",
                    }}
                  >
                    ขอทราบเส้นทาง
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 contact-map-col2">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.5860813312356!2d78.39170750000001!3d17.479514499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9185969ec633%3A0x50c71941b7b668de!2sMalaysian%20Twp%2C%20KPHB%205th%20Phase%2C%20Kukatpally%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1680424988372!5m2!1sen!2sin"
                width="100%"
                height="440"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        <div
          className="row idmb-icon-res"
          style={{
            height: "500px",
            background:
              "linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%)",
            alignItems: "center",
            justifySelf: "center",
          }}
        >
          <div
            className="roww-new-idmb contact-row-new-idmb-res"
            style={{
              backgroundSize: "100% 100%",
              height: "200px",
              width: "700px",
              margin: "auto",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {/* rowwIcon */}
            <div
              className="row-new-idmb-title"
              style={{ margin: "10px 10px 0px" }}
            >
              <span style={{ color: "white" }}>ติดตามการทำงานได้ที่</span>
              <br></br>

              <img src={YOU} className="iconImg" alt="..." />
              <img src={FB} className="iconImg" alt="..." />
              <img src={TIK} className="iconImg" alt="..." />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
