import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

import Layout from "../Layout";
import "./index.css";
import BK from "../../assets/images/n5.png";
import Q from "../../assets/images/q4.png";
import Footer from "../Footer";
import FB from "../../assets/images/fb.png";
import YOU from "../../assets/images/you.png";
import TIK from "../../assets/images/tic.png";
import C1 from "../../assets/images/Choice1.png";
import C2 from "../../assets/images/Choice2.png";
import C3 from "../../assets/images/Choice3.png";
import C4 from "../../assets/images/Choice4.png";

function Q5() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = (option) => {
    setShowPopup(true);
    setSelectedOption(option);
  };

  const handleGoToNextPage = () => {
    navigate("/final");
  };

  const handleTryAgain = () => {
    setShowPopup(false);
    setSelectedOption(null);
  };
  return (
    <div className="page-container">
      <Layout />
      <br></br>
      <br></br>
      <div>
        <div className="row row-quiz-question">
          <div className="col">
            <div className="quiz-img-pos mg-t-200">
              <img src={Q} alt="" className="row-quiz-question-img-res" />
            </div>
          </div>
          <div className="col">
            <div className=" question-card ">
              <div className=" question-content">
                <h4 className="check-question check-font">
                  5. หากท่านลงทุนออนไลน์ แล้วไม่สามารถถอนเงิน ลงทุนหรือกำไร
                  ออกจากระบบได้ ท่านควรทำอย่างไร
                </h4>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <button
                    type="button"
                    className="btn quiz-question-btn btn-quiz"
                    onClick={() => handleButtonClick("ก")}
                  >
                    ก.
                  </button>

                  <h4 className="question-card-h4">
                    ทำตาม Customer service แนะนํา
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <button
                    type="button"
                    className="btn quiz-question-btn btn-quiz "
                    onClick={() => handleButtonClick("ข")}
                  >
                    ข.
                  </button>

                  <h4 className="question-card-h4">
                    จ่ายค่าธรรมเนียม, ภาษีตามที่ Call center ให้คำแนะนํา
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <button
                    type="button"
                    className="btn quiz-question-btn btn-quiz"
                    onClick={() => handleButtonClick("ค")}
                  >
                    ค.
                  </button>

                  <h4 className="question-card-h4">
                    {" "}
                    ลงทุนต่อไปสักระยะแต่ต้องตรวจสอบข่าวสารการลงทุน
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <button
                    type="button"
                    className="btn quiz-question-btn btn-quiz"
                    onClick={() => handleButtonClick("ง")}
                  >
                    ง.
                  </button>

                  <h4 className="question-card-h4">
                    {" "}
                    หยุดลงทุนทันที ตรวจสอบข่าวสารการลงทุนและแจ้งความออนไลน์
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={showPopup}
            onHide={() => {}}
            centered
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "600px",
              height: "450px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "20px",
              boxShadow: "none",
            }}
            className="custom-modal"
          >
            <Modal.Header
              closeButton={false}
              style={{ border: "none" }}
            ></Modal.Header>
            <Modal.Body style={{ border: "none", marginLeft: "-10px" }}>
              {/* Popup Content */}
              {selectedOption === "ง" && (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="button"
                      className="btn quiz-question-btn quiz { 'foo-bar': true }"
                    >
                      5
                    </button>
                    <h3 style={{ padding: "0px 30px" }} className="check-font">
                      ถูกต้อง!
                    </h3>
                  </div>
                  <div className="text-alert">
                    การรู้เท่าทันกลโกงของมิจฉาชีพ <br />
                    จะช่วยให้คุณปลอดภัยในโลกออนไลน์
                  </div>
                </>
              )}
              {selectedOption !== "ง" && (
                <>
                  <div style={{ display: "flex" }}>
                    <button
                      type="button"
                      className="btn quiz-question-btn btn-quiz quiz"
                    >
                      5
                    </button>
                    <h3
                      style={{ color: "#060290", paddingLeft: "14px" }}
                      className="check-font"
                    >
                      น่าเสียดาย คุณตอบผิด : {selectedOption}{" "}
                    </h3>
                  </div>
                  <div className="text-alert">
                    การรู้เท่าทันกลโกงของมิจฉาชีพ<br></br>
                    จะช่วยให้คุณปลอดภัยในโลกออนไลน์
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer style={{ border: "none", justifyContent: "center" }}>
              {selectedOption !== "ง" && (
                <button
                  onClick={handleTryAgain}
                  className="btn btn-next"
                  style={{
                    color: "#FF8A00",
                    background: "#FFFFFF",
                    border: "4px solid #FF8A00",
                    borderRadius: "8px",
                    marginRight: "10px",
                  }}
                >
                  ลองอีกครั้ง
                </button>
              )}
              <button onClick={handleGoToNextPage} className="btn btn-next">
                ไปข้อถัดไป
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <hr />
      <div className="footer-quiz-res-mb">
        <Footer />
      </div>
    </div>
  );
}

export default Q5;
