import CryptoJS from "crypto-js";
import moment from "moment";
const date = moment().format("YYYY-MM-DD");

const keyString = process.env.REACT_APP_KEY_STRING;
const key = CryptoJS.enc.Utf8.parse(keyString.substring(0, 32));
const iv = CryptoJS.enc.Utf8.parse(keyString.substring(0, 16));

function encryptAES(plainText) {
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
}

function decryptAES(encryptedText) {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export default {
  encryptAES,
  decryptAES,
};
